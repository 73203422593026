import { Box } from "@mui/material"

import circle from './images/circle.svg'

import camera from './images/ic_camera.svg'
import dialpad from './images/ic_dialpad.svg'
import autorenew from './images/ic_autorenew.svg'
import { StampViewType } from "../app/Stamp"
import { ComponentType, PropsWithChildren } from "react"

const StampView: StampViewType = ({ point, onClickExchange, onClickShowNumPad, onClickShowQRReader}) => {
    return (
        <Box>
            <Box sx={{
                color: 'rgba(255,69,0,0.8)',
                fontSize: '10vw',
                textAlign: 'center',
                paddingTop: '5vh'
            }}>現在のポイント</Box>
            <Box sx={{
                position: 'relative',
                width: '60%',
                marginLeft: '20%',
                marginTop: '5vh',
                marginBottom: '12vw',
            }}>
                <img src={circle} style={styles.circleImg} alt=""></img>
                <Box sx={{
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    textAlign: 'center',
                    fontSize: '15vw',
                }}><Box component="span" sx={{
                    position: 'absolute',
                    display: 'inline-block',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: 'rgba(255,69,0,0.8)',
                }}>{point}pt</Box></Box>
            </Box>
            <Button1 onClick={() => onClickShowQRReader()}>
                <img style={styles.button1Img} src={camera} alt="" />
                <span>QRコード</span>
            </Button1>
            <Button1  onClick={() => onClickShowNumPad()}>
                <img style={styles.button1Img} src={dialpad} alt="" />
                <span>番号入力</span>
            </Button1>
            <Button1 onClick={() => onClickExchange()}>
                <img style={styles.button1Img} src={autorenew} alt="" />
                <span>交換</span>
            </Button1>
            {/*
            {this.qrReader()}
            {this.numPad()}
            <SkyLight hideOnOverlayClicked ref={ref => this.skyLight = ref} title="お知らせ" dialogStyles={styles.skyLight}>{this.props.notice.split("\n").map(v => <p>{v}</p>)}</SkyLight>
            */}
        </Box>
    )
}

export default StampView

const styles = {
    title: {

    },
    circle: {

    },
    circleImg: {
        margin: 0,
        padding: 0,
        display: 'block',
        width: '100%',
        height: '100%',
        stroke: '#0000ff',

    },
    pt: {

    },

    ptNumber: {


    },

    button1: {

    },
    button1Img: {
        padding: 0,
        margin: 0,
        marginBottom: '1vh',
        width: '100%',
    },
    button3: {
        boxSizing: 'border-box',
        width: '90%',
        marginLeft: '5%',
        marginTop: '5vh',
    },
    skyLight: {
        width: '90%',
        marginLeft: '-45%',
    }
}


const Button1: ComponentType<PropsWithChildren<{
    onClick: () => any,
    visible?: boolean,
}>> = ({ onClick, visible, children }) => {
    return (
        <Box sx={{
            display: 'inline-block',
            position: 'relative',
            boxSizing: 'border-box',
            width: '20%',
            marginLeft: '10%',
            textAlign: 'center',
            fontWeight: 'bold',
            color: 'rgba(0,0,255,0.6)',
            fontSize: '3vw',
            visibility: visible === false ? 'hidden' : 'visible',
        }} onClick={() => onClick()}>
            {children}
        </Box>
    )
}