import { ComponentType, useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Product, SearchProductResult } from "../types/product"
import { SearchResult } from '../types/search'
import { ProductsViewType } from "./Products"
import { useServer } from "./providers/server"

const ReserveProducts: ComponentType<Props> = ({ viewComponent: View }) => {
    const productGroupId = useParams().productGroupId
    const server = useServer()
    const navigate = useNavigate()
    const [fetched, setFetched] = useState(false)
    const [{ list, total, productGroup }, setResult] = useState<SearchProductResult>({ list: [], total: 0 })
    const fetchAsync = useCallback(async () => {
        setResult(await server.searchProducts({ page: 1, perPage: 10, productGroupId }))
    }, [productGroupId, server])
    useEffect(() => {
        fetchAsync().then(() => setFetched(true))
    }, [fetchAsync])
    const handleClick = (item: Product) => {
        navigate(`/reserve/products/${item.id}`)
    }
    const title = useMemo(() => {
        if (productGroup == null) {
            return '事前注文'
        } else {
            return productGroup.label
        }
    }, [productGroup])
    return (
        <View
            list={list}
            total={total}
            onClick={handleClick}
            title={title}
            isReserve={true}
        />
    )
}

export default ReserveProducts

interface Props {
    viewComponent: ProductsViewType
}


